<template>
	<div>
		<a-spin v-show="!showModal" :spinning="loading">
			<a-table :dataSource="list" :columns="columns" :pagination="pagination" rowKey="id" :scroll="{ x: 2000 }">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'oneMoneyScale'">
						{{ record.oneMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'twoMoneyScale'">
						{{ record.twoMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'storekeeperMoneyScale'">
						{{ record.storekeeperMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'snackOneMoneyScale'">
						{{ record.snackOneMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'snackTwoMoneyScale'">
						{{ record.snackTwoMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'snackStorekeeperMoneyScale'">
						{{ record.snackStorekeeperMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'ticketOneMoneyScale'">
						{{ record.ticketOneMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'ticketTwoMoneyScale'">
						{{ record.ticketTwoMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'ticketStorekeeperMoneyScale'">
						{{ record.ticketStorekeeperMoneyScale }} %佣金
					</template>
					<template v-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
							<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
							</a-button>
							<template #overlay>
								<a-menu>
									<div @click="onSee(record)">
										<a-menu-item>查看</a-menu-item>
									</div>
									<div v-permission="['member_distributor_level_edit']" @click="onEdit(record)">
										<a-menu-item>
											编辑
										</a-menu-item>
									</div>
									<!-- <div @click="onDelete(record)">
										<a-menu-item >
											刪除
										</a-menu-item>
									</div> -->
								</a-menu>
							</template>
						</a-dropdown>
					</template>
				</template>
			</a-table>
		</a-spin>
		<temp v-if="showModal" :id="id" :isSee="isSee" @back="onBack"></temp>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import temp from './temp.vue';
	import { getDistributionLevelList, saveDistributionLevel, updateDistributionLevel, deleteDistributionLevel } from '@/service/modules/distribution.js';
	export default {
		components: { Icon, temp },
		data() {
			return {
				loading: false,
				list: [],
				columns: [{
					title: '等级名称',
					dataIndex: 'title'
				}, {
					title: '划线价格(元)',
					dataIndex: 'originalPrice'
				}, {
					title: '售价(元)',
					dataIndex: 'price'
				}, {
					title: '商品自购佣金',
					key: 'oneMoneyScale'
				}, {
					title: '商品邀请人返佣',
					key: 'twoMoneyScale'
				}, {
					title: '商品团队长分红',
					key: 'storekeeperMoneyScale'
				}, {
					title: '卖品自购佣金',
					key: 'snackOneMoneyScale'
				}, {
					title: '卖品邀请人返佣',
					key: 'snackTwoMoneyScale'
				}, {
					title: '卖品团队长分红',
					key: 'snackStorekeeperMoneyScale'
				}, {
					title: '影票自购返佣',
					key: 'ticketOneMoneyScale'
				}, {
					title: '影票邀请人返佣',
					key: 'ticketTwoMoneyScale'
				}, {
					title: '影票团队长分红',
					key: 'ticketStorekeeperMoneyScale'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right'
				}],
				id: 0,
				isSee: false,
				showModal: false,
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if(isRef) {
					this.getData();
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getDistributionLevelList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onSubmit() {
				this.$refs.form.validateFields().then(async ()=> {
					let postData = JSON.parse(JSON.stringify(this.editData));
					this.loading = true;
					try {
						let ret = await updateDistributionLevel(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('修改成功');
							this.showModal = false;
							this.getData();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onSee() {
				this.showModal = true;
				this.isSee = true;
				this.id = item.id;
			},
			onEdit(item) {
				this.showModal = true;
				this.isSee = false;
				this.id = item.id;
			},
			onDelete(item) {
				this.$confirm({
					title:'提示',
					content: '删除操作不可逆，确定要删除吗？',
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteDistributionLevel({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style>
</style>
