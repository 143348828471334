<template>
	<div>
		<Header title="编辑分销等级" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 20px;" ref="form" name="form" :model="editData" :labelCol="{ span: 5 }" :wrapperCol="{ span: 14 }" @finish="onSubmit">
				<a-form-item label="等级名称" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input :disabled="isSee" v-model:value="editData.title" placeholder="请输入等级名称"></a-input>
				</a-form-item>
				
				<a-form-item label="划线价" name="originalPrice" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.originalPrice" :min="0" placeholder="请输入"></a-input-number>
				</a-form-item>
				
				<a-form-item label="售价" name="price" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.price" :min="0" placeholder="请输入"></a-input-number>
				</a-form-item>
				
				<!-- <a-form-item label="排序" name="sort" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number v-model:value="editData.sort" :min="0" placeholder="请输入"></a-input-number>
				</a-form-item> -->
				
				<a-form-item label="是否在小程序显示" name="isShow">
					<a-radio-group :disabled="isSee" v-model:value="editData.isShow">
						<a-radio :value="0">否</a-radio>
						<a-radio :value="1">是</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<a-form-item label="商品自购佣金" name="oneMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.oneMoneyScale" :min="0" :max="oneMoneyScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="商品邀请人返佣" name="twoMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.twoMoneyScale" :min="0" :max="twoMoneyScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="商品团队长分红" name="storekeeperMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.storekeeperMoneyScale" :min="0" :max="storekeeperMoneyScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="卖品自购佣金" name="snackOneMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.snackOneMoneyScale" :min="0" :max="oneSnackScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="卖品邀请人返佣" name="snackTwoMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.snackTwoMoneyScale" :min="0" :max="twoSnackScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="卖品团队长分红" name="snackStorekeeperMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.snackStorekeeperMoneyScale" :min="0" :max="snackStorekeeperMoneyScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="影票自购佣金" name="ticketOneMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.ticketOneMoneyScale" :min="0" :max="oneTicketScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="影票邀请人返佣" name="ticketTwoMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.ticketTwoMoneyScale" :min="0" :max="twoTicketScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="影票团队长分红" name="ticketStorekeeperMoneyScale" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input-number :disabled="isSee" v-model:value="editData.ticketStorekeeperMoneyScale" :min="0" :max="ticketStorekeeperMoneyScaleMax" placeholder="请输入" @change="maxChange"></a-input-number>
					% 返佣
				</a-form-item>
				
				<a-form-item label="等级权益" name="content" :rules="[{required: true, message: '必填项不允许为空'}]">
					<div>
						<Editor v-model:value="editData.content" :configSetting="{ disabled: isSee }"></Editor>
					</div>
				</a-form-item>
				
				<div style="text-align: center;color: red;">注：返佣计算=分销设置的返佣计算节点✖返佣比例</div>
				
				<a-row>
					<a-col :offset="4">
						<div style="margin-top: 20px;margin-left: 40px;">
							<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
							<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
						</div>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import { Icon } from '@/components/icon/icon.js';
	import Editor from '@/components/editor/editor.vue';
	import { getDistributionLevelDetail, updateDistributionLevel } from '@/service/modules/distribution.js';
	export default {
		components: { Header, Icon, Editor },
		props: {
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				editData: {
					isShow: 1
				},
				oneMoneyScaleMax: 99,
				twoMoneyScaleMax: 99,
				storekeeperMoneyScaleMax: 99,
				oneSnackScaleMax: 99,
				twoSnackScaleMax: 99,
				snackStorekeeperMoneyScaleMax: 99,
				oneTicketScaleMax: 99,
				twoTicketScaleMax: 99,
				ticketStorekeeperMoneyScaleMax: 99
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				this.loading = true;
				try {
					let ret = await updateDistributionLevel(this.editData);
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('编辑成功');
						this.onBack(true);
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getDistributionLevelDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						if (isNaN(ret.data.isShow)) {
							ret.data.isShow = 1;
						}
						this.editData = ret.data;
						this.maxChange();
					}
				} catch(e) {
					this.loading = false;
				}
			},
			maxChange() {
				this.oneMoneyScaleMax = 99 - Number(this.editData.twoMoneyScale) - Number(this.editData.storekeeperMoneyScale);
				this.twoMoneyScaleMax = 99 - Number(this.editData.oneMoneyScale) - Number(this.editData.storekeeperMoneyScale);
				this.storekeeperMoneyScaleMax = 99 - Number(this.editData.oneMoneyScale) - Number(this.editData.twoMoneyScale);
				this.oneSnackScaleMax = 99 - Number(this.editData.snackTwoMoneyScale) - Number(this.editData.snackStorekeeperMoneyScale);
				this.twoSnackScaleMax = 99 - Number(this.editData.snackOneMoneyScale) - Number(this.editData.snackStorekeeperMoneyScale);
				this.snackStorekeeperMoneyScaleMax = 99 - Number(this.editData.snackOneMoneyScale) - Number(this.editData.snackTwoMoneyScale);
				this.oneTicketScaleMax = 99 - Number(this.editData.ticketTwoMoneyScale) - Number(this.editData.ticketStorekeeperMoneyScale);
				this.twoTicketScaleMax = 99 - Number(this.editData.ticketOneMoneyScale) - Number(this.editData.ticketStorekeeperMoneyScale);
				this.ticketStorekeeperMoneyScaleMax = 99 - Number(this.editData.ticketOneMoneyScale) - Number(this.editData.ticketTwoMoneyScale)
			},
		}
	}
</script>

<style>
</style>
